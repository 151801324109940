/*
  This mixin handles logic common to every page that shows a data table.
  This includes fetching records on create, refreshing data when indicated by store,
  and pagination logic depending on current tab.
*/

export default {
  data() {
    return {
      perPage: 100,
      loading: false,
    };
  },
  created() {
    this.getRecords();
  },
  watch: {
    refresh(val) {
      // only refresh page when it is meant for the current component
      if (val === this.$options._componentTag || val === "*") {
        this.getRecords();
      }
    },
  },
  computed: {
    refresh() {
      return this.$store.state.ui.refreshRows;
    },
    navigationId() {
      // depending if current view has subtabs or not
      return this.activeSubTab || this.activeTabId;
    },
    currentTab() {
      return this.tabs.find((tab) => tab.id === this.navigationId);
    },
    leftDisabled() {
      return this.currentTab.currentPage === 1;
    },
    rightDisabled() {
      return (
        this.currentTab.currentPage >=
        Math.ceil(this.currentTab.itemCount / this.perPage)
      );
    },
  },
  methods: {
    changePage(forward = true) {
      this.currentTab.currentPage = forward
        ? this.currentTab.currentPage + 1
        : this.currentTab.currentPage - 1;
      this.currentTab.offset = (this.currentTab.currentPage - 1) * this.perPage;
      this.getRecords();
    },
  },
};
