import axios from "axios";
import getEnv from "@/utils/env";

const IS_DROUGHT_URL = getEnv("VUE_APP_IS_DROUGHT_URL");


export function getIsDrought() {
  return axios.get(`${IS_DROUGHT_URL}`);
}

export function postIsDrought(is_drought) {
  return axios.post(`${IS_DROUGHT_URL}`, {is_drought});
}
