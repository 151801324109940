import actionNeeded from "./actionNeeded.vue";
import creditEntered from "./creditEntered.vue";
import denied from "./denied.vue";
import processedInSeamless from "./processedInSeamless.vue";
import readyForBilling from "./readyForBilling.vue";
import snoozing from "./snoozing.vue";
import waitingForDirectorApproval from "./waitingForDirectorApproval.vue";

export default {
  actionNeeded,
  creditEntered,
  denied,
  processedInSeamless,
  readyForBilling,
  snoozing,
  waitingForDirectorApproval,
};
