export default {
  data() {
    return {
      activeTab: -1,
      activeTabId: "",
    };
  },
  computed: {
    hash() {
      // removes the '#''
      return this.$route.hash.substring(1);
    },
  },
  watch: {
    hash() {
      this.newTabIndex();
    },
  },
  created() {
    this.newTabIndex();
  },
  methods: {
    newTabIndex() {
      let tabIndex = -1;
      if (this.hash) {
        tabIndex = this.tabs.findIndex((tab) => tab.id === this.hash);
      } else {
        tabIndex = this.tabs.findIndex((tab) => tab.active);
      }
      this.activeTab = tabIndex === -1 ? 0 : tabIndex;
      this.onTabChange(this.activeTab);
    },
    onTabChange(tabIndex) {
      for (let tab of this.tabs) {
        tab.active = false; // set all tabs as inactive
      }

      const activeTab = this.tabs[tabIndex];
      this.activeTabId = activeTab.id;
      activeTab.active = true;

      if (activeTab.link) {
        this.$router.push(activeTab.link);
      } else {
        location.hash = activeTab.id;
      }
    },
  },
};
