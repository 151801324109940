<template>
  <div>
    <div class="subtabsBar pt-2">
      <b-button-group class="mx-1 mb-2 subtabs float-left">
        <b-button
          v-for="tab in tabs"
          :key="tab.id"
          class="text-capitalize"
          :variant="activeSubTab === tab.id ? 'dark' : 'outline-primary'"
          @click="activeSubTab = tab.id"
          >{{ tab.title.toUpperCase() }} ({{ tab.itemCount }})</b-button
        >
      </b-button-group>
    </div>

    <div
        v-for="tab in tabs"
        v-show="tab.id === activeSubTab"
        :key="tab.id"
    >
      <!-- Actions bar -->
      <div class="subtabsBar pt-2">
        <b-row style="justify-content: flex-end">
          <!-- Add tool tip -->
          <tooltip>
            <div>
              Export the selected sub-tab data by Credit Entered date range (inclusive).
            </div>
          </tooltip>

          <!-- Exports -->
          <div class="export-actions" style="display: flex; margin-right: 16px">
            <b-input-group size="sm" class='mr-1'>
              <b-input-group-prepend class='' is-text>Export date range</b-input-group-prepend>
              <b-form-input class='' size='sm' v-model='exportStartdate' type="date" ></b-form-input>
              <b-form-input class='' size='sm' v-model='exportEndDate' type="date" ></b-form-input>
            </b-input-group>

            <b-button
              variant="primary"
              class="float-right"
              style="width: 250px"
              @click="() => onExportCSV(true)"
            >Export Expanded CSV</b-button>
          </div>
        </b-row>
      </div>

      <paginator
        :leftDisabled="leftDisabled"
        :rightDisabled="rightDisabled"
        :perPage="perPage"
        :offset="tab.offset"
        :itemCount="tab.itemCount"
        @leftClick="changePage(false)"
        @rightClick="changePage(true)"
      ></paginator>

      <custom-table
        class="pt-2"
        :items="tab.items"
        :table="tab"
        :busy="loading"
        :fields="fields"
        @rowClicked="rowClicked"
      >
      </custom-table>
    </div>
  </div>
</template>

<script>
import { listAdjustApplications } from "@/apis/adjustmentApplications";
import { getReadyForBillingCSV } from "@/apis/exports";
import customTable from "@/components/customTable.vue";
import paginator from "@/components/paginator.vue";
import Tooltip from "@/components/tooltip.vue";
import dataViewer from "@/mixins/dataViewer";
import utils from "@/mixins/utils";

export default {
  name: "creditEntered",
  mixins: [dataViewer, utils],
  components: { customTable, paginator, Tooltip },
  data() {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);


    return {
      activeSubTab: "creditNotEntered",
      tabs: [
        {
          id: "creditNotEntered",
          title: "Credit not Entered",
          items: [],
          itemCount: 0,
          offset: 0,
          currentPage: 1,
        },
        {
          id: "creditNotDetected",
          title: "Credit not Detected",
          items: [],
          itemCount: 0,
          offset: 0,
          currentPage: 1,
        },
        {
          id: "creditPending",
          title: "Credit Pending",
          items: [],
          itemCount: 0,
          offset: 0,
          currentPage: 1,
        },
        {
          id: "creditApplied",
          title: "Credit Applied",
          items: [],
          itemCount: 0,
          offset: 0,
          currentPage: 1,
        },
      ],
      fields: [
        {
          key: 'credit_status',
          sortable: true,
          class: 'credit-status-column',
          formatter: (value) => {
            if (value == 'credit_not_pending') {
              return 'Credit not detected'
            } else if (value == 'credit_applied') {
              return 'Credit Applied'
            } else if (value == 'credit_pending') {
              return 'Credit pending'
            }
          }
        },
        {
          key: 'account_number',
          label: 'Account number',
          class: 'account-number-column',
        },
        {
          key: 'service_address_number',
          label: 'Service Address Number',
          class: 'service-address-number-column',
        },
        {
          key: 'name',
          label: 'Customer name',
          sortable: true,
          class: 'text-column'
        },
        {
          key: 'bill_to_adjust_amount',
          label: 'Original Bill Amount',
          sortable: true,
          class: 'dollar-column',
        },
        {
          key: 'bill_amount_after_adjustment',
          label: 'Adjusted Bill Amount',
          sortable: true,
          class: 'dollar-column',
        },
        {
          key: 'bill_credit',
          label: 'Credit Amount',
          class: 'dollar-column',
          formatter: (value) => {
            return -1*value
          },
          sortable: true
        },
        {
          key: 'reason',
          class: 'text-column',
          sortable: true,
          formatter: (value, key, item) => {

            let type = ''
            if (item['pool_fill_reason']) {
              type  = 'Pool fill'
            } else if (item['timer_description']) {
              type  = 'Timer malfunction'
            } else if (item['repair_description']) {
              type  = 'Repair'
            } else if (item['covid_reason']) {
              type  = 'COVID-related incident'
            } else {
              type = '[Other]'
            }

            return `${type} | ${item['bill_to_adjust_date']} bill`
          }
        },
        {
          key: 'completed_date',
          label: 'Credit Entered Date',
          sortable: true,
          class: 'date-column',
        },
        {
          key: 'created_ts',
          label:'Submission Date',
          sortable: true,
          class: 'date-column',
          formatter: (value) => {
            return value.split('T')[0];
          }
        },
        {
          key: 'is_paper',
          label:'Paper Submission',
          sortable: true,
          class: 'is-paper-column',
          formatter: (value) => {
            if (value == true) {
                return 'Yes'
            } else if (value == false) {
              return ''
            }
          }
        },
        {
          key: 'is_covid_related',
          label:'COVID Related',
          sortable: true,
          class: 'covid-related-column',
          formatter: (value) => {
            if (value == true) {
                return 'Yes'
            } else if (value == false) {
              return 'No'
            }
          }
        }
      ],

      exportStartdate: sevenDaysAgo.toISOString().split('T')[0], //aka today...
      exportEndDate: (new Date()).toISOString().split('T')[0], //aka today...
    };
  },
  computed: {
    creditNotEnteredTab() {
      return this.tabs.find((tab) => tab.id === "creditNotEntered");
    },
    creditNotDetectedTab() {
      return this.tabs.find((tab) => tab.id === "creditNotDetected");
    },
    creditPendingTab() {
      return this.tabs.find((tab) => tab.id === "creditPending");
    },
    creditAppliedTab() {
      return this.tabs.find((tab) => tab.id === "creditApplied");
    },
  },
  methods: {
    rowClicked(row) {
      this.$store.dispatch("ui/openModal", {
        data: row,
        type: "adjustment-application",
        ui: this,
      });
    },
    getNotEnteredRecords() {
      return listAdjustApplications({
        is_processed: false,
        state: 'completed',
        credit_status: 'credit_not_entered',
        offset: this.creditNotEnteredTab.offset,
        limit: this.perPage,
      });
    },
    getNotDetectedRecords() {
      return listAdjustApplications({
        is_processed: false,
        state: 'completed',
        credit_status: 'credit_not_pending',
        offset: this.creditNotDetectedTab.offset,
        limit: this.perPage,
      });
    },
    getPendingRecords() {
      return listAdjustApplications({
        is_processed: false,
        state: 'completed',
        credit_status: 'credit_pending',
        offset: this.creditPendingTab.offset,
        limit: this.perPage,
      });
    },
    getAppliedRecords() {
      return listAdjustApplications({
        is_processed: false,
        state: 'completed',
        credit_status: 'credit_applied',
        offset: this.creditAppliedTab.offset,
        limit: this.perPage,
      });
    },


    getRecords() {
      this.loading = true;

      Promise.all([
        this.getNotEnteredRecords(),
        this.getNotDetectedRecords(),
        this.getPendingRecords(),
        this.getAppliedRecords(),
      ])
        .then(
          ([
            { data: creditNotEntered },
            { data: creditNotDetected },
            { data: creditPending },
            { data: creditApplied },
          ]) => {
            this.creditNotEnteredTab.itemCount = creditNotEntered.total_items;
            this.creditNotEnteredTab.items = creditNotEntered.items;

            this.creditNotDetectedTab.itemCount = creditNotDetected.total_items;
            this.creditNotDetectedTab.items = creditNotDetected.items;

            this.creditPendingTab.itemCount = creditPending.total_items;
            this.creditPendingTab.items = creditPending.items;

            this.creditAppliedTab.itemCount = creditApplied.total_items;
            this.creditAppliedTab.items = creditApplied.items;

            this.$emit(
              "tabCount",
              this.creditNotEnteredTab.itemCount
              + this.creditNotDetectedTab.itemCount
              + this.creditPendingTab.itemCount
              + this.creditAppliedTab.itemCount
            );
          }
        )
        .catch((e) => {
          this.notify("danger", "Adjustment Applications could not be fetched", "" + e);
        })
        .finally(() => {
          this.loading = false;
        });
    },


    onExportCSV(audit) {
      let creditStatus = 'credit_not_entered';
      if (this.activeSubTab === 'creditNotDetected') {
        creditStatus = 'credit_not_pending';
      } else if (this.activeSubTab === 'creditPending') {
        creditStatus = 'credit_pending';
      } else if (this.activeSubTab === 'creditApplied') {
        creditStatus = 'credit_applied';
      }

      getReadyForBillingCSV(
        audit,
        'completed',
        creditStatus,
        this.exportStartdate,
        this.exportEndDate,
      )
        .then(({ data }) => {
          this.downloadCSV(data, 'bill_adjustments.csv');
        })
        .catch((e) => {
          this.notify(
            "danger",
            "Failed to export adjustment applications.",
            e.response.data.message
          );
        });
    },
  },
};
</script>

<style scoped lang="scss">

.export-actions {

  .input-group, input, button {
    height: 35px;
  }

  button {
    text-transform: uppercase;
  }
}
</style>
