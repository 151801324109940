<template>
  <div>
    <paginator
      :leftDisabled="leftDisabled"
      :rightDisabled="rightDisabled"
      :perPage="perPage"
      :offset="currentTab.offset"
      :itemCount="currentTab.itemCount"
      @leftClick="changePage(false)"
      @rightClick="changePage(true)"
    ></paginator>

    <custom-table
      class="pt-2"
      :items="currentTab.items"
      :busy="loading"
      :fields="fields"
      @rowClicked="rowClicked"
    >
    </custom-table>
  </div>
</template>

<script>
import { listAdjustApplications } from "@/apis/adjustmentApplications";
import customTable from "@/components/customTable.vue";
import paginator from "@/components/paginator.vue";
import dataViewer from "@/mixins/dataViewer";
export default {
  name: "snoozing",
  mixins: [dataViewer],
  components: { customTable, paginator },
  data() {
    return {
      activeTabId: "snoozing",
      tabs: [
        {
          id: "snoozing",
          items: [],
          itemCount: 0,
          offset: 0,
          currentPage: 1,
        },
      ],
      fields: [
        {
          key: 'created_ts',
          label:'Submission Date',
          sortable: true,
          class: 'date-column',
          formatter: (value) => {
            return value.split('T')[0];
          }
        },
        {
          key: 'wake_up_date',
          label: 'Wake Up Date',
          sortable: true,
          class: 'date-column',
        },
        {
          key: 'reason_for_state',
          label:'Reason for Snooze',
          class: 'text-column',
        },
        {key: 'account_number', sortable: false, class: 'text-center account-number-column' },
        {key: 'name', sortable: false, class: 'text-column' },
        {
          key: 'service_address_full',
          label: 'Street Address',
          formatter: (value, key, item) => {
            return `${item['service_address_full']}, ${item['city']}, ${item['zip_code']}`;
          }
        },
        {
          key: 'email',
          sortable: false,
          class: 'text-column',
          tdClass: 'no-text-transform',
          formatter: (value) => {
            // Add Zero Width Space element so the browser breaks on it
            return `${value.replace('@', '\u200B@')}`
          }
        },
        {key: 'phone', sortable: false, class: 'phone-column'  },
        {key: 'reason', sortable: false, tdClass: 'no-text-transform', class: 'text-column' },
        {
          key: 'bill_cycle',
          label: 'Bill Cycle',
          sortable: false,
          class: 'bill-cycle-column',
        },
        {
          key: 'bill_to_adjust_date',
          label:'Selected High Bill Date',
          sortable: true,
          class: 'date-column',
        },
        {
          key: 'bill_credit',
          label: 'Resulting Credit',
          sortable: true,
          class: 'dollar-column',
          formatter: (value) => {
            if (value){
              return '$'+value.toFixed(2)
            }
          }
        },
        {
          key: 'is_paper',
          label:'Paper Submission',
          sortable: true,
          class: 'is-paper-column',
          formatter: (value) => {
            if (value == true) {
                return 'Yes'
            } else if (value == false) {
              return ''
            }
          }
        },
        {
          key: 'is_covid_related',
          label:'COVID Related',
          sortable: true,
          class: 'covid-related-column',
          formatter: (value) => {
            if (value == true) {
                return 'Yes'
            } else if (value == false) {
              return 'No'
            }
          }
        }
      ],
    };
  },
  computed: {
    noRowSelected() {
      return this.snoozingTab.items.every((i) => !i.selected);
    },
    snoozingTab() {
      return this.tabs.find((tab) => tab.id === "snoozing");
    },
  },
  methods: {
    rowClicked(row) {
      this.$store.dispatch("ui/openModal", {
        data: row,
        type: "adjustment-application",
        ui: this,
      });
    },

    getRecords() {
      this.loading = true;

      listAdjustApplications({
        is_processed: false,
        state: 'waiting',
        offset: this.currentTab.offset,
        limit: this.perPage,
      })
        .then(
          ({ data }) => {
            this.snoozingTab.itemCount = data.total_items;
            this.snoozingTab.items = data.items;

            this.$emit(
              "tabCount",
              this.snoozingTab.itemCount
            );
          }
        )
        .catch((e) => {
          this.notify("danger", "Adjustment Applications could not be fetched", "" + e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
