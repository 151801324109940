<template>
  <div>
    <!-- Actions bar -->
    <div class="subtabsBar pt-2">
      <b-row style="justify-content: space-between">
        <!-- Batch Actions -->
        <b-col cols="auto">
          <b-button
            variant="primary"
            class="ml-2 mr-2"
            style="height: 35px; text-transform: uppercase;"
            @click="() => moveAllItemsToCompleted()"
          >Move these applications to Credit Entered tab</b-button>
        </b-col>

        <!-- Exports -->
        <div style="display: flex; margin-right: 16px">
          <b-button
            variant="primary"
            class="ml-2 mr-2"
            style="height: 35px; text-transform: uppercase;"
            @click="() => onExportCSV(false)"
          >Export Upload CSV</b-button>

          <b-button
            variant="primary"
            class="float-right"
            style="height: 35px; text-transform: uppercase;"
            @click="() => onExportCSV(true)"
          >Export Expanded CSV</b-button>
        </div>
      </b-row>
    </div>

    <paginator
      :leftDisabled="leftDisabled"
      :rightDisabled="rightDisabled"
      :perPage="perPage"
      :offset="currentTab.offset"
      :itemCount="currentTab.itemCount"
      @leftClick="changePage(false)"
      @rightClick="changePage(true)"
    ></paginator>

    <custom-table
      class="pt-2"
      :items="currentTab.items"
      :busy="loading"
      :fields="fields"
      @rowClicked="rowClicked"
    >
    </custom-table>
  </div>
</template>

<script>
import { listAdjustApplications, putAdjustApplicationStateBatch } from "@/apis/adjustmentApplications";
import { getReadyForBillingCSV } from "@/apis/exports";
import customTable from "@/components/customTable.vue";
import paginator from "@/components/paginator.vue";
import dataViewer from "@/mixins/dataViewer";
import utils from "@/mixins/utils";

export default {
  name: "readyForBilling",
  mixins: [dataViewer, utils],
  components: { customTable, paginator },
  data() {
    return {
      activeTabId: "readyForBilling",
      tabs: [
        {
          id: "readyForBilling",
          items: [],
          itemCount: 0,
          offset: 0,
          currentPage: 1,
        },
      ],
      fields: [
        {
          key: 'credit_status',
          sortable: true,
          class: 'credit-status-column',
          formatter: (value) => {
            if (value == 'credit_not_pending') {
              return 'Credit not detected'
            } else if (value == 'credit_applied') {
              return 'Credit Applied'
            } else if (value == 'credit_pending') {
              return 'Credit pending'
            }
          }
        },
        {
          key: 'account_number',
          label: 'Account number',
          class: 'account-number-column',
        },
        {
          key: 'service_address_number',
          label: 'Service Address Number',
          class: 'service-address-number-column',
        },
        {
          key: 'name',
          label: 'Customer name',
          sortable: true,
          class: 'text-column'
        },
        {
          key: 'bill_to_adjust_amount',
          label: 'Original Bill Amount',
          sortable: true,
          class: 'dollar-column',
        },
        {
          key: 'bill_amount_after_adjustment',
          label: 'Adjusted Bill Amount',
          sortable: true,
          class: 'dollar-column',
        },
        {
          key: 'bill_credit',
          label: 'Credit Amount',
          class: 'dollar-column',
          formatter: (value) => {
            return -1*value
          },
          sortable: true
        },
        {
          key: 'reason',
          class: 'text-column',
          sortable: true,
          formatter: (value, key, item) => {

            let type = ''
            if (item['pool_fill_reason']) {
              type  = 'Pool fill'
            } else if (item['timer_description']) {
              type  = 'Timer malfunction'
            } else if (item['repair_description']) {
              type  = 'Repair'
            } else if (item['covid_reason']) {
              type  = 'COVID-related incident'
            } else {
              type = '[Other]'
            }

            return `${type} | ${item['bill_to_adjust_date']} bill`
          }
        },
        {
          key: 'completed_date',
          label: 'Credit Entered Date',
          sortable: true,
          class: 'date-column',
        },
        {
          key: 'created_ts',
          label:'Submission Date',
          sortable: true,
          class: 'date-column',
          formatter: (value) => {
            return value.split('T')[0];
          }
        },
        {
          key: 'is_paper',
          label:'Paper Submission',
          sortable: true,
          class: 'is-paper-column',
          formatter: (value) => {
            if (value == true) {
                return 'Yes'
            } else if (value == false) {
              return ''
            }
          }
        },
        {
          key: 'is_covid_related',
          label:'COVID Related',
          sortable: true,
          class: 'covid-related-column',
          formatter: (value) => {
            if (value == true) {
                return 'Yes'
            } else if (value == false) {
              return 'No'
            }
          }
        }
      ],
    };
  },
  computed: {
    noRowSelected() {
      return this.readyForBillingTab.items.every((i) => !i.selected);
    },
    readyForBillingTab() {
      return this.tabs.find((tab) => tab.id === "readyForBilling");
    },
  },
  methods: {
    rowClicked(row) {
      this.$store.dispatch("ui/openModal", {
        data: row,
        type: "adjustment-application",
        ui: this,
      });
    },

    getRecords() {
      this.loading = true;

      listAdjustApplications({
        is_processed: false,
        state: 'ready_for_billing',
        offset: this.currentTab.offset,
        limit: this.perPage,
      })
        .then(
          ({ data }) => {
            this.readyForBillingTab.itemCount = data.total_items;
            this.readyForBillingTab.items = data.items;

            this.$emit(
              "tabCount",
              this.readyForBillingTab.itemCount
            );
          }
        )
        .catch((e) => {
          this.notify("danger", "Adjustment Applications could not be fetched", "" + e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onExportCSV(audit) {
      getReadyForBillingCSV(audit)
        .then(({ data }) => {
          this.downloadCSV(data, 'bill_adjustments.csv');
        })
        .catch((e) => {
          this.notify(
            "danger",
            "Failed to export adjustment applications.",
            e.response.data.message
          );
        });
    },

    moveAllItemsToCompleted() {
      putAdjustApplicationStateBatch(
        this.currentTab.items.map((x) => x.application_id),
        'completed',
      ).then(() => {
        // Trigger page refresh
        this.$store.commit("ui/setRefreshRows", "*");
      });
    }
  },
};
</script>

<style scoped></style>
