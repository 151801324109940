import axios from "axios";
import getEnv from "@/utils/env";

const EXPORT_URL = getEnv("VUE_APP_EXPORT_URL");


export function getReadyForBillingCSV(audit = false, state, credit_status, creditEnteredStartDate, creditEnteredEndDate) {
  let query_params = `audit=${audit}`;

  if (state) {
    query_params += `&state=${state}`;
  }

  if (credit_status) {
    query_params += `&credit_status=${credit_status}`;
  }

  if (creditEnteredStartDate) {
    query_params += `&completed_date=${encodeURIComponent('>=')}${creditEnteredStartDate}`;

    if (creditEnteredEndDate) {
      query_params += `,${encodeURIComponent('<=')}${creditEnteredEndDate}`;
    }
  }
  else if (creditEnteredEndDate) {
    query_params += `&completed_date=${encodeURIComponent('<=')}${creditEnteredEndDate}`;
  }

  return axios.get(`${EXPORT_URL}/ready_for_billing?${query_params}`);
}
