<!--
  Component template for all tables used in the project.
  renders selection column, icons, and fill rows with color if selected/payment.
-->

<template>
  <b-table
    v-bind="$attrs"
    v-on="$listeners"
    head-variant="light"
    :fields="finalFields"
    thead-class="text-uppercase"
    :tbody-tr-class="rowClass"
    class="text-center text-capitalize"
    show-empty
    hover
    sort-by="difference"
    @row-clicked="(evt) => $emit('rowClicked', evt)"
  >
    <template #head(selected)>
      <b-form-checkbox
        v-model="allSelected"
        @change="(val) => (val ? selectAllRows() : clearSelected())"
      >
      </b-form-checkbox>
    </template>
    <template #cell(selected)="row">
      <b-form-checkbox v-model="row.item.selected" @change="checkAllSelection">
      </b-form-checkbox>
    </template>
    <template #cell(id)="row">
      <b-icon
        v-if="!selectable && table && table.icon"
        :icon="table.icon"
        scale="1.5"
        :variant="table.variant"
        class="mr-2"
      ></b-icon>

      <b-icon v-else scale="1.5" class="mr-2"></b-icon>
      {{ accountNumber(row) }}
    </template>
    <template #table-busy>
      <div class="text-center text-dark my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  name: "customTable",
  props: {
    selectable: { type: Boolean, default: false },
    table: Object,
    fields: { type: Array },
  },
  inheritAttrs: false,

  created() {
    if (this.selectable) {
      this.fields.unshift({ key: "selected", thClass: "select-column" });
    }
  },
  data() {
    return {
      allSelected: false,
    };
  },
  methods: {
    selectAllRows() {
      this.$attrs.items.forEach((item) => {
        item.selected = true;
      });
      this.allSelected = true;
    },
    clearSelected() {
      this.$attrs.items.forEach((item) => {
        item.selected = false;
      });
      this.allSelected = false;
    },
    checkAllSelection() {
      const status = this.$attrs.items.every((item) => item.selected);
      this.allSelected = status;
    },
    rowClass(item) {
      if (!item) return "";

      if (item.pending_sync) {
        return "light-orange";
      }

      if (this.payments && item.difference !== undefined) {
        return item.difference <= -10 ? "light-red" : "light-green";
      }

      return item.selected ? "light-blue" : "";
    },
  },
  computed: {
    finalFields() {
      return this.fields.filter((field) => field.visible !== false);
    },
  },
};
</script>

<style>
.select-column {
  width: 10px;
}

input[type="checkbox"] {
  transform: scale(3);
}
</style>
