<template>
  <b-container fluid>
    <pageHeader title="Bill Adjustments"></pageHeader>

    <b-tabs
      content-class="mt-3"
      pills
      v-model="activeTab"
      @input="onTabChange"
      class="text-uppercase"
      active-nav-item-class="tab-border-bottom"
    >
      <b-tab
        v-for="tab in tabs"
        :key="tab.title"
        title-link-class="text-color-primary"
      >
        <template #title>
          <b-badge :variant="tab.active ? 'dark' : 'light'">{{
            tab.itemCount
          }}</b-badge
          ><br />
          <span class="text-color-primary">{{ tab.title }}</span>
        </template>

        <keep-alive>
          <component
            v-bind:is="tab.component"
            @tabCount="(count) => tabCount(tab.component, count)"
          ></component>
        </keep-alive>
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import pageHeader from "@/components/pageHeader.vue";
import tabsNavigation from "@/mixins/tabsNavigation";
import billAdjustmentTabs from "../billAdjustmentTabs";
import { getIsDrought } from "@/apis/isDrought";
import { setCreditStatus, setBillCycle } from "@/apis/adjustmentApplications";

export default {
  name: "BillAdjustments",
  components: {
    pageHeader,
    ...billAdjustmentTabs,
  },
  mixins: [tabsNavigation],
  created() {
    this.getIsDrought();
    this.setCreditStatus();
    this.setBillCycle();
  },
  methods: {
    tabCount(tabId, count) {
      this.tabs.find((tab) => tab.id === tabId).itemCount = count;
    },

    getIsDrought() {
      getIsDrought()
        .then(({ data }) => {
          // set is_drought to value from most recent record in table
          let idx = data.length - 1;
          if (data[idx]) {
            let drought_status = data[idx]['is_drought'];
            this.$store.commit("ui/setIsDrought", drought_status);
          } else {
            this.$store.commit("ui/setIsDrought", false);
          }
        })
        .catch((e) => {
          this.notify(
            "danger",
            "Failed to retrieve drought data.",
            e.response.data.message
          );
        })
    },

    setCreditStatus() {
      setCreditStatus()
        .then(() => {
          // Trigger page refresh
          this.$store.commit("ui/setRefreshRows", "*");

          this.notify(
            "info",
            "Succesfully updated credit status",
          );
        })
        .catch((e) => {
          this.notify(
            "danger",
            "Failed to update credit status, retrying...",
            e.response.data.message,
          );
          setCreditStatus()
            .then(() => {
              // Trigger page refresh
              this.$store.commit("ui/setRefreshRows", "*");

              this.notify(
                "info",
                "Succesfully updated credit status",
              );
            })
            .catch((e) => {
              this.notify(
                "danger",
                "Failed to update credit status. Please try again or contact your system administrator.",
                e.response.data.message
              );
            });
        });
    },

    setBillCycle() {
      setBillCycle()
        .then(() => {})
        .catch((e) => {
          this.notify(
            "danger",
            "Failed to set bill cycle.",
            e.response.data.message
          );
        });
    },
  },
  data() {
    return {
      tabs: [
        {
          id: "actionNeeded",
          component: "actionNeeded",
          title: "Action Needed",
          active: true,
          itemCount: 0,
        },
        {
          id: "snoozing",
          component: "snoozing",
          title: "Snoozing",
          itemCount: 0,
        },
        {
          id: "readyForBilling",
          component: "readyForBilling",
          title: "Ready For Billing",
          itemCount: 0,
        },
        {
          id: "creditEntered",
          component: "creditEntered",
          title: "Credit Entered",
          itemCount: 0,
        },
        {
          id: "denied",
          component: "denied",
          title: "Denied",
          itemCount: 0,
        },
        {
          id: "processedInSeamless",
          component: "processedInSeamless",
          title: "Processed in Seamless",
          itemCount: 0,
        },
        {
          id: "waitingForDirectorApproval",
          component: "waitingForDirectorApproval",
          title: "Waiting for Director Approval",
          itemCount: 0,
        },
      ],
    };
  },
};
</script>

<style>
  .nonVisible {
    visibility: hidden;
  }

  .tableHeader {
    font-family: "Poppins";
    text-align: left;
    background: #f8f8f8;
  }

  table {
    table-layout: fixed;
  }
</style>
