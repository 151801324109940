<template>
  <div>
    <div class="subtabsBar pt-2">
      <b-button-group class="mx-1 mb-2 subtabs float-left">
        <b-button
          v-for="tab in tabs"
          :key="tab.id"
          class="text-capitalize"
          :variant="activeSubTab === tab.id ? 'dark' : 'outline-primary'"
          @click="activeSubTab = tab.id"
          >{{ tab.title.toUpperCase() }} ({{ tab.itemCount }})</b-button
        >
      </b-button-group>
    </div>

    <div
        v-for="tab in tabs"
        v-show="tab.id === activeSubTab"
        :key="tab.id"
    >
      <paginator
        :leftDisabled="leftDisabled"
        :rightDisabled="rightDisabled"
        :perPage="perPage"
        :offset="tab.offset"
        :itemCount="tab.itemCount"
        @leftClick="changePage(false)"
        @rightClick="changePage(true)"
      ></paginator>

      <custom-table
        class="pt-2"
        :items="tab.items"
        :table="tab"
        :busy="loading"
        :fields="fields"
        @rowClicked="rowClicked"
      >
      </custom-table>
    </div>
  </div>
</template>

<script>
import { listAdjustApplications } from "@/apis/adjustmentApplications";
import customTable from "@/components/customTable.vue";
import paginator from "@/components/paginator.vue";
import dataViewer from "@/mixins/dataViewer";
export default {
  name: "processedInSeamless",
  components: { paginator, customTable },
  mixins: [dataViewer],
  data() {
    return {
      activeSubTab: "creditNotEntered",
      tabs: [
        {
          id: "creditNotEntered",
          title: "Credit not Entered",
          items: [],
          itemCount: 0,
          offset: 0,
          currentPage: 1,
        },
        {
          id: "creditNotDetected",
          title: "Credit not Detected",
          items: [],
          itemCount: 0,
          offset: 0,
          currentPage: 1,
        },
        {
          id: "creditPending",
          title: "Credit Pending",
          items: [],
          itemCount: 0,
          offset: 0,
          currentPage: 1,
        },
        {
          id: "creditApplied",
          title: "Credit Applied",
          items: [],
          itemCount: 0,
          offset: 0,
          currentPage: 1,
        },
      ],
      fields: [
        {
          key: 'created_ts',
          label:'Submission Date',
          sortable: true,
          class: 'date-column',
          formatter: (value) => {
            return value.split('T')[0];
          }
        },
        {
          key: 'business_name',
          label: 'Class',
          class: 'class-column',
          formatter: (value) => {
            if (value === null){
              return 'Residential'
            }
            else {
              return 'Commercial'
            }
          },
          sortable: false
        },
        {key: 'account_number', sortable: false, class: 'text-center account-number-column' },
        {key: 'name', sortable: false, class: 'text-column' },
        {
          key: 'service_address_full',
          label: 'Street Address',
          formatter: (value, key, item) => {
            return `${item['service_address_full']}, ${item['city']}, ${item['zip_code']}`;
          }
        },
        {
          key: 'email',
          sortable: false,
          class: 'text-column',
          tdClass: 'no-text-transform',
          formatter: (value) => {
            // Add Zero Width Space element so the browser breaks on it
            return `${value.replace('@', '\u200B@')}`
          }
        },
        {key: 'phone', sortable: false, class: 'phone-column' },
        {key: 'reason', sortable: false, tdClass: 'no-text-transform', class: 'text-column' },
        {
          key: 'bill_cycle',
          label: 'Bill Cycle',
          class: 'bill-cycle-column',
          sortable: false,
        },
        {
          key: 'is_paper',
          label:'Paper Submission',
          sortable: true,
          class: 'is-paper-column',
          formatter: (value) => {
            if (value == true) {
                return 'Yes'
            } else if (value == false) {
              return ''
            }
          }
        },
        {
          key: 'is_covid_related',
          label:'COVID Related',
          sortable: true,
          class: 'covid-related-column',
          formatter: (value) => {
            if (value == true) {
                return 'Yes'
            } else if (value == false) {
              return 'No'
            }
          }
        },
        {
          key: 'state',
          label: 'State',
          class: 'state-column',
        }
      ],
    };
  },
  computed: {
    creditNotEnteredTab() {
      return this.tabs.find((tab) => tab.id === "creditNotEntered");
    },
    creditNotDetectedTab() {
      return this.tabs.find((tab) => tab.id === "creditNotDetected");
    },
    creditPendingTab() {
      return this.tabs.find((tab) => tab.id === "creditPending");
    },
    creditAppliedTab() {
      return this.tabs.find((tab) => tab.id === "creditApplied");
    },
  },
  methods: {
    rowClicked(row) {
      this.$store.dispatch("ui/openModal", {
        data: row,
        type: "adjustment-application",
        ui: this,
      });
    },

    getNotEnteredRecords() {
      return listAdjustApplications({
        is_processed: true,
        credit_status: 'credit_not_entered',
        offset: this.creditNotEnteredTab.offset,
        limit: this.perPage,
      });
    },
    getNotDetectedRecords() {
      return listAdjustApplications({
        is_processed: true,
        credit_status: 'credit_not_pending',
        offset: this.creditNotDetectedTab.offset,
        limit: this.perPage,
      });
    },
    getPendingRecords() {
      return listAdjustApplications({
        is_processed: true,
        credit_status: 'credit_pending',
        offset: this.creditPendingTab.offset,
        limit: this.perPage,
      });
    },
    getAppliedRecords() {
      return listAdjustApplications({
        is_processed: true,
        credit_status: 'credit_applied',
        offset: this.creditAppliedTab.offset,
        limit: this.perPage,
      });
    },


    getRecords() {
      this.loading = true;

      Promise.all([
        this.getNotEnteredRecords(),
        this.getNotDetectedRecords(),
        this.getPendingRecords(),
        this.getAppliedRecords(),
      ])
        .then(
          ([
            { data: creditNotEntered },
            { data: creditNotDetected },
            { data: creditPending },
            { data: creditApplied },
          ]) => {
            this.creditNotEnteredTab.itemCount = creditNotEntered.total_items;
            this.creditNotEnteredTab.items = creditNotEntered.items;

            this.creditNotDetectedTab.itemCount = creditNotDetected.total_items;
            this.creditNotDetectedTab.items = creditNotDetected.items;

            this.creditPendingTab.itemCount = creditPending.total_items;
            this.creditPendingTab.items = creditPending.items;

            this.creditAppliedTab.itemCount = creditApplied.total_items;
            this.creditAppliedTab.items = creditApplied.items;

            this.$emit(
              "tabCount",
              this.creditNotEnteredTab.itemCount
              + this.creditNotDetectedTab.itemCount
              + this.creditPendingTab.itemCount
              + this.creditAppliedTab.itemCount
            );
          }
        )
        .catch((e) => {
          this.notify("danger", "Adjustment Applications could not be fetched", "" + e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
