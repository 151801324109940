<template>
  <div class="overflow-auto float-right">
    <span
      >{{ offset + 1 }}-{{ Math.min(offset + perPage, itemCount) }} OF
      {{ itemCount }}</span
    >
    <b-button
      :disabled="leftDisabled"
      variant="outline-dark"
      @click="$emit('leftClick')"
      class="ml-2 paginator-icon"
    >
      <b-icon icon="caret-left-fill"></b-icon>
    </b-button>
    <b-button
      :disabled="rightDisabled"
      variant="outline-dark"
      @click="$emit('rightClick')"
      class="ml-2 paginator-icon"
    >
      <b-icon icon="caret-right-fill"></b-icon>
    </b-button>
  </div>
</template>

<script>
export default {
  name: "paginator",
  props: {
    offset: Number,
    perPage: Number,
    itemCount: Number,
    leftDisabled: Boolean,
    rightDisabled: Boolean,
  },
};
</script>
