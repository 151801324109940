<template>
  <div>
    <b-icon
      class="ml-2 mr-2 blue-icon"
      style="height: 35px"
      :id="id"
      icon="exclamation-circle-fill"
      scale="1.2"
      c
    ></b-icon>
    <b-tooltip
      class="blue-tip"
      custom-class="text-left"
      :target="id"
      triggers="hover"
      placement="bottomright"
    >
      <slot></slot>
    </b-tooltip>
  </div>
</template>

<script>
let count = 0;

export default {
  name: "Tooltip",
  props: {
    id: {
      type: String,
      default: function() {
        return this.$options.name + '-' + count++;
      }
    },
  },
};
</script>
<style>
.tooltip-inner {
  background-color: #00416a !important;
  color: #fff !important;
  border-radius: 0;
  max-width: 40vw;
  padding: 14px;
  text-align: left;
}

.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #00416a !important;
}

.bs-tooltip-top .arrow::before {
  border-top-color: #00416a !important;
}

.blue-icon {
  color: #00416a !important;
}
</style>
